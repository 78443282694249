export const env = {
  REACT_APP_COGNITO_IDENTITY_POOL_ID:
    process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
  REACT_APP_COGNITO_USER_POOL_CLIENT_ID:
    process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID,
  REACT_APP_COGNITO_USER_POOL_ID: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  REACT_APP_EXPORT_BUCKET_NAME: process.env.REACT_APP_EXPORT_BUCKET_NAME,
  REACT_APP_SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  REACT_APP_SENTRY_ORG: process.env.REACT_APP_SENTRY_ORG,
  REACT_APP_SENTRY_PROJECT: process.env.REACT_APP_SENTRY_PROJECT,
  REACT_APP_SERVICES_HOST: process.env.REACT_APP_SERVICES_HOST,
  REACT_APP_SERVICES_REGION: process.env.REACT_APP_SERVICES_REGION,
  REACT_APP_SPEECH_BUCKET_NAME: process.env.REACT_APP_SPEECH_BUCKET_NAME,
  REACT_APP_STAGE: process.env.REACT_APP_STAGE,
};
